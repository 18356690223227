var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "approval" }, [
    _c("div", [
      _c(
        "div",
        { staticClass: "header" },
        [
          _c("span", { staticClass: "span-box", on: { click: _vm.goOut } }, [
            _c(
              "span",
              { staticClass: "backIcon white" },
              [
                _c("a-icon", {
                  staticStyle: {
                    color: "#D8D8D8",
                    width: "32px",
                    height: "32px",
                    "font-size": "20px"
                  },
                  attrs: { type: "left" }
                })
              ],
              1
            ),
            _c("span", { staticClass: "goto" }, [_vm._v("返回")])
          ]),
          _vm.showContent
            ? _c("span", { staticClass: "tabsl" }, [
                _c(
                  "span",
                  {
                    class: _vm.defaultTabs == 1 ? "isActive" : "",
                    on: {
                      click: function($event) {
                        return _vm.saveData(1)
                      }
                    }
                  },
                  [_vm._v(" 基础设置 ")]
                ),
                _c(
                  "span",
                  {
                    class: _vm.defaultTabs == 2 ? "isActive" : "",
                    on: {
                      click: function($event) {
                        return _vm.saveData(2)
                      }
                    }
                  },
                  [_vm._v(" 表单设计 ")]
                ),
                _c(
                  "span",
                  {
                    class: _vm.defaultTabs == 3 ? "isActive" : "",
                    on: {
                      click: function($event) {
                        return _vm.saveData(3)
                      }
                    }
                  },
                  [_vm._v(" 流程设计 ")]
                ),
                _c(
                  "span",
                  {
                    class: _vm.defaultTabs == 4 ? "isActive" : "",
                    on: {
                      click: function($event) {
                        return _vm.saveData(4)
                      }
                    }
                  },
                  [_vm._v(" 高级设置 ")]
                )
              ])
            : _vm._e(),
          _c(
            "a-button",
            {
              staticStyle: {
                width: "72px",
                height: "32px",
                background: "#1890ff",
                "border-radius": "4px",
                color: "#ffffff"
              },
              attrs: { size: "large" },
              on: {
                click: function($event) {
                  return _vm.saveData(null)
                }
              }
            },
            [_vm._v(" 发布 ")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "containerSet" },
        [
          _c("base-set", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.defaultTabs === 1,
                expression: "defaultTabs === 1"
              }
            ],
            on: { formTypeChange: _vm.onFormTypeChange },
            model: {
              value: _vm.baseinfo,
              callback: function($$v) {
                _vm.baseinfo = $$v
              },
              expression: "baseinfo"
            }
          }),
          _c("form-set", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.defaultTabs === 2,
                expression: "defaultTabs === 2"
              }
            ],
            ref: "formSet",
            on: { change: _vm.onFormListChange },
            model: {
              value: _vm.formlist,
              callback: function($$v) {
                _vm.formlist = $$v
              },
              expression: "formlist"
            }
          }),
          _c("process-set", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.defaultTabs === 3,
                expression: "defaultTabs === 3"
              }
            ],
            attrs: { setProcess: _vm.process, formlist: _vm.formlist },
            model: {
              value: _vm.process,
              callback: function($$v) {
                _vm.process = $$v
              },
              expression: "process"
            }
          }),
          _c("senior-set", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.defaultTabs === 4,
                expression: "defaultTabs === 4"
              }
            ],
            model: {
              value: _vm.setting,
              callback: function($$v) {
                _vm.setting = $$v
              },
              expression: "setting"
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }