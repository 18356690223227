import _objectSpread from "/data/jenkins/workspace/prod-e-retail/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
import { mapState } from "vuex"; // eslint-disable-line no-unused-vars

export default _objectSpread(_objectSpread({
  components: {},
  props: {},
  data: function data() {
    return {};
  }
}, mapState(["settings"])), {}, {
  //   ...mapState(["permissions_site"]),
  //   copyright_title() {
  //     const currentSite = this.permissions_site.filter(
  //       item => item.currSite === true
  //     );
  //     if (currentSite.length > 0 && !!currentSite[0]["copyRight"]) {
  //       return currentSite[0]["copyRight"];
  //     }
  //     return this.settings.system_title;
  //   }
  // },
  watch: {},
  created: function created() {},
  mounted: function mounted() {},
  methods: {}
});