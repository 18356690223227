import "core-js/modules/es.array.concat";
import request from "@/utils/request"; // -------------------------------------------------- 银行 -----------------------------------
// 分页查询待办池
// export function orderInfosReq(params) {
//   return request({
//     url: "/mfs/api/v1/orderInfos",
//     method: "GET",
//     params: params
//   });
// }

export function orderInfosReq(params) {
  return request({
    url: "/mfs/api/v1/customerInfos/getCustomerInPage",
    method: "GET",
    params: params
  });
} // 查询字段设置

export function fieldSetsListAll(productId) {
  return request({
    url: "finance/api/v1/fieldSets/listAll/".concat(productId),
    method: "GET"
  });
} // 分页查询我的客户
// export function bankCustomersReq(params) {
//   return request({
//     url: "/mfs/api/v1/orderInfos/bank/customers",
//     method: "GET",
//     params: params
//   });
// }

export function bankCustomersReq(params) {
  return request({
    url: "/mfs/api/v1/customerInfos/bank/customers",
    method: "GET",
    params: params
  });
} // 查询产品信息

export function getAllProduct(data) {
  return request({
    url: "/finance/api/v1/products/listAll",
    method: "GET",
    params: data
  });
} // 查看渠道下产品

export function productLists(channelId) {
  return request({
    url: "/finance/api/v1/channels/productList/".concat(channelId),
    method: "GET"
  });
} // 领办

export function bankReceiveOrderReq(data) {
  return request({
    url: "/mfs/api/v1/orderInfos/bankReceiveOrder",
    method: "post",
    data: data
  });
} // 委派

export function distributeOrderToBankReq(data, userId) {
  return request({
    url: "/mfs/api/v1/orderInfos/distributeOrderToBank/".concat(userId),
    method: "post",
    data: data
  });
} // 查询获取所有客户经理

export function bankMemberListAllReq(params) {
  return request({
    url: "/mfs/api/v1/bankMembers/listAll",
    method: "GET",
    params: params
  });
} // -------------------------------------------------- 风控 -----------------------------------
// 提交订单到风控

export function submitOrderToBankReq(data) {
  return request({
    url: "/mfs/api/v1/orderInfos/submitOrderToBank",
    method: "post",
    data: data
  });
} // 分页查询我的客户
// export function riskCustomersReq(params) {
//   return request({
//     url: "/mfs/api/v1/orderInfos/risk/customers",
//     method: "GET",
//     params: params
//   });
// }

export function riskCustomersReq(params) {
  return request({
    url: "/mfs/api/v1/customerInfos/risk/customers",
    method: "GET",
    params: params
  });
} // 领办

export function riskReceiveOrderReq(data) {
  return request({
    url: "/mfs/api/v1/orderInfos/riskReceiveOrder",
    method: "post",
    data: data
  });
} // 委派

export function distributeOrderToRiskReq(data, userId) {
  return request({
    url: "/mfs/api/v1/orderInfos/distributeOrderToRisk/".concat(userId),
    method: "post",
    data: data
  });
} // 查询获取所有客户经理

export function channelMemberListAllReq(params) {
  return request({
    url: "/mfs/api/v1/channelMembers/listAll",
    method: "GET",
    params: params
  });
} // -------------------------------------------------- 客户经理 -----------------------------------
// 分页查询客户池

export function getCustomerInPageReq(params) {
  return request({
    url: "/mfs/api/v1/customerInfos/getCustomerInPage",
    method: "GET",
    params: params
  });
} // 分页查询我的客户

export function getMyCustomerInPageReq(params) {
  return request({
    url: "/mfs/api/v1/customerInfos/getMyCustomerInPage",
    method: "GET",
    params: params
  });
} // 客户经理领办

export function customerLeadReq(data) {
  return request({
    url: "/mfs/api/v1/followUpRecords/lead",
    method: "post",
    data: data
  });
} // 委派给客户经理

export function customerAppointReq(data) {
  return request({
    url: "/mfs/api/v1/followUpRecords/appoint",
    method: "post",
    data: data
  });
} // 提交订单到风控

export function submitOrderToRiskReq(data) {
  return request({
    url: "/mfs/api/v1/orderInfos/submitOrderToRisk",
    method: "post",
    data: data
  });
} // 更新订单状态

export function updateOrderStatusReq(orderInfoId, status) {
  return request({
    url: "/mfs/api/v1/orderInfos/updateOrderStatus/".concat(orderInfoId, "/").concat(status),
    method: "put"
  });
} // 更新订单信息

export function updateOrderInfoReq(data) {
  return request({
    url: "/finance/api/v1/orderInfos",
    method: "put",
    data: data
  });
} // 获取所有产品

export function productsListAll(params) {
  return request({
    url: "/finance/api/v1/products/listAll",
    method: "GET",
    params: params
  });
} // 获取订单详情

export function orderInfoReq(orderInfoId) {
  return request({
    url: "/finance/api/v1/orderInfos/".concat(orderInfoId),
    method: "get"
  });
} // 新增

export function createReq(data) {
  return request({
    url: "/mfs/api/v1/customerInfos",
    method: "POST",
    data: data
  });
} // 拒绝

export function rejectOrder(data) {
  return request("/mfs/api/v1/orderInfos/rejectOrder", {
    method: "PUT",
    data: data
  });
} // 预览报告

export function reviewReport(customerInfoId, templateName) {
  return request({
    url: "/mfs/api/v1/customerInfos/reviewReport/".concat(customerInfoId, "/").concat(templateName),
    method: "GET"
  });
} // 下载报告

export function downloadReport(customerInfoId, templateName) {
  return request({
    url: "/mfs/api/v1/customerInfos/downloadReport/".concat(customerInfoId, "/").concat(templateName),
    method: "GET"
  });
} // 获取预览报告url

export function reviewUrl(orderInfoIds, customerId, templateName) {
  return request({
    url: "/finance/api/v1/customerInfos/reviewUrl/".concat(orderInfoIds, "/").concat(customerId, "/").concat(templateName),
    method: "GET"
  });
} // 修改

export function updateReq(data) {
  return request("/finance/api/v1/customerInfos", {
    method: "PUT",
    data: data
  });
} // 修改订单信息

export function updateReqs(data) {
  return request("/finance/api/v1/orderInfos", {
    method: "PUT",
    data: data
  });
} // 根据id查询详情

export function detailReq(customerInfoId) {
  return request({
    url: "/finance/api/v1/customerInfos/".concat(customerInfoId),
    method: "GET"
  });
} // 删除

export function deleteReq(params) {
  return request({
    url: "/mfs/api/v1/customerInfos/".concat(params),
    method: "DELETE"
  });
} // 批量删除

export function deleteBatchReq(data) {
  return request({
    url: "/mfs/api/v1/customerInfos",
    method: "DELETE",
    data: data
  });
} // 审批通过

export function pass(id, data) {
  return request({
    url: "/approval/api/v1/userFlowApprovers/pass/".concat(id),
    method: "PUT",
    data: data
  });
} // 修改订单状态

export function updateOrderInfos(data) {
  return request({
    url: "/finance/api/v1/orderInfos",
    method: "PUT",
    data: data
  });
} // 审批详情

export function userApproDetails(userApproId) {
  return request({
    url: "/approval/api/v1/userAppros/userApproDetails/".concat(userApproId),
    method: "GET"
  });
} // 审批拒绝

export function reject(id, data) {
  return request({
    url: "/approval/api/v1/userFlowApprovers/reject/".concat(id),
    method: "PUT",
    data: data
  });
} // 获取订单信息

export function retrieveReq(params) {
  return request({
    url: "/finance/api/v1/orderInfos",
    method: "GET",
    params: params
  });
} // 获取字段设置

export function fieldsListAll(params) {
  return request({
    url: "/finance/api/v1/fieldSets/listAll",
    method: "GET",
    params: params
  });
} // 新增客户信用状况

export function customerCredits(customerId, data) {
  return request({
    url: "/finance/api/v1/customerCredits/save/".concat(customerId),
    method: "POST",
    data: data
  });
} // 新增客户信用状况

export function customerOperates(data) {
  return request({
    url: "/finance/api/v1/customerOperates",
    method: "POST",
    data: data
  });
} // 新增资产信息

export function customerAssets(customerId, data) {
  return request({
    url: "/finance/api/v1/customerAssets/save/".concat(customerId),
    method: "POST",
    data: data
  });
} // 新增担保物信息

export function guaranteeGoods(customerId, data) {
  return request({
    url: "/finance/api/v1/guaranteeGoods/save/".concat(customerId),
    method: "POST",
    data: data
  });
}
export function customerSpouseInfos(data) {
  return request({
    url: "/finance/api/v1/customerSpouseInfos",
    method: "POST",
    data: data
  });
} // 新增担保物信息

export function customerSpouseCredits(customerId, data) {
  return request({
    url: "/finance/api/v1/customerSpouseCredits/save/".concat(customerId),
    method: "POST",
    data: data
  });
}