var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-form",
    {
      staticClass: "forms",
      attrs: { form: _vm.form, labelCol: { span: 6 }, wrapperCol: { span: 18 } }
    },
    [
      _c(
        "a-row",
        [
          this.$route.params.status != 3
            ? _c(
                "div",
                {
                  staticStyle: {
                    "margin-bottom": "20px",
                    "text-align": "right"
                  }
                },
                [
                  _vm.editables
                    ? [
                        _c("a-button", { on: { click: _vm.quxiao } }, [
                          _vm._v("取消 ")
                        ]),
                        _c(
                          "a-button",
                          {
                            staticStyle: { "margin-left": "15px" },
                            attrs: { type: "primary" },
                            on: { click: _vm.save }
                          },
                          [_vm._v(" 保存 ")]
                        )
                      ]
                    : _c(
                        "a-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              _vm.editables = true
                            }
                          }
                        },
                        [_vm._v(" 编辑 ")]
                      )
                ],
                2
              )
            : _vm._e(),
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c("XqFormTable", {
                attrs: {
                  form: _vm.form,
                  dataSource: _vm.dataSource,
                  dynamicData: _vm.dynamicData,
                  item: _vm.formFields["guaranteeList"],
                  editable: _vm.editables
                }
              })
            ],
            1
          ),
          this.$route.params.status != 3
            ? _c(
                "a-col",
                { staticStyle: { "margin-top": "10px" }, attrs: { span: 24 } },
                [
                  _c("XqItem", {
                    attrs: {
                      form: _vm.form,
                      dataSource: _vm.dataSource,
                      dynamicData: _vm.dynamicData,
                      item: _vm.formFields["guaranteeExtend"],
                      editable: _vm.editables
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }