import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.string.pad-start";
import Vue from "vue"; // 时间戳转换为 YYYY-MM-DD HH:mm:ss

Vue.filter("formatDate", function (originVal) {
  // 先把传参毫秒转化为new Date()
  var dt = new Date(originVal);
  var y = dt.getFullYear(); // 月份是从0开始,需要+1。  +''是把数字转化为字符串,padStart(2,'0')是把字符串设置为2位数,不足2位则在开头加'0'

  var m = (dt.getMonth() + 1 + "").padStart(2, "0");
  var d = (dt.getDate() + "").padStart(2, "0");
  var hh = (dt.getHours() + "").padStart(2, "0");
  var mm = (dt.getMinutes() + "").padStart(2, "0");
  var ss = (dt.getSeconds() + "").padStart(2, "0");
  return "".concat(y, "-").concat(m, "-").concat(d, "-").concat(hh, ":").concat(mm, ":").concat(ss);
});