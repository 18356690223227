import "core-js/modules/es.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: "AddNode",
  components: {},
  props: {
    childNode: {
      type: Object,
      default: function _default() {}
    },
    nodeId: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      /* approver notifier condition  block*/
      visible: false
    };
  },
  computed: {},
  watch: {},
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    // 隐藏添加气泡
    addType: function addType(type) {
      var nodeId = this.nodeId;
      nodeId++;
      console.log("nodeId", nodeId);
      this.$emit("change", this.nodeId);
      this.visible = false;
      var data;

      if (type !== "block") {
        if (type === "approver") {
          data = {
            nodeType: "approver",
            name: "审批人",
            formSetting: [],
            activateType: 0,
            noneActionerAction: 0,
            props: {
              nodeId: nodeId,
              values: "",
              name: "",
              type: "APPROVER_SELECT",
              persons: "",
              range: "allUser",
              isMulti: 0
            },
            childNode: this.childNode
          };
        } else if (type === "notifier") {
          data = {
            nodeType: "notifier",
            name: "抄送人",
            userIds: "",
            // 选择的人员ID
            roles: "",
            // 抄送的角色
            supervisor: "",
            // 选择的主管等级
            isUserChose: true,
            props: {
              nodeId: nodeId,
              name: "",
              userList: []
            },
            childNode: this.childNode
          };
        }
      } else {
        data = {
          nodeType: "block",
          conditionNodes: [{
            name: "条件1",
            // 节点名称
            operationName: "请选择条件",
            // 条件节点解释
            operationCode: "",
            // 条件节点运算逻辑
            nodeType: "condition",
            priorityLevel: 1,
            childNode: this.childNode,
            props: {
              nodeId: nodeId,
              conditions: []
            }
          }, {
            name: "条件2",
            // 节点名称
            operationName: "请选择条件",
            // 条件节点解释
            operationCode: "",
            // 条件节点运算逻辑
            nodeType: "condition",
            priorityLevel: 2,
            childNode: null,
            props: {
              nodeId: nodeId,
              conditions: []
            }
          }]
        };
      }

      this.$emit("update:childNode", data);
    }
  }
};