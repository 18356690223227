import "core-js/modules/es.array.filter";
import "core-js/modules/es.regexp.constructor";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/es.string.replace";
import Vue from "vue"; // 时间戳转换为 YYYY-MM-DD HH:mm:ss

Vue.filter("formatDate", function (timeStamp, format) {
  if (timeStamp) {
    format = format || "YYYY-MM-DD";
    var week = ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"];
    var date = new Date(parseInt(timeStamp));
    var o = {
      "M+": date.getMonth() + 1,
      "D+": date.getDate(),
      "h+": date.getHours() % 12,
      "H+": date.getHours(),
      "m+": date.getMinutes(),
      "s+": date.getSeconds(),
      "q+": Math.floor((date.getMonth() + 3) / 3),
      "S+": date.getMilliseconds(),
      "W+": week[date.getDay()]
    };

    if (/(Y+)/.test(format)) {
      format = format.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
    }

    for (var k in o) {
      if (new RegExp("(" + k + ")").test(format)) {
        format = format.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length));
      }
    }

    return format;
  }
});