import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.split";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  data: function data() {
    return {
      infoAddress: []
    };
  },
  created: function created() {
    this.infoAddress = this.$route.query.info.split(",");
    console.log(this.infoAddress, 3333);
  },
  methods: {
    goBack: function goBack() {
      this.$router.go(-1);
    }
  }
};