import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.map";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.to-string";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import EmployeeSelect from "../RoleSelect/employee-select";
import RoleSelect from "../RoleSelect";
import { isJson } from "./index";
export default {
  name: "NotifierDrawer",
  components: {
    EmployeeSelect: EmployeeSelect,
    RoleSelect: RoleSelect
  },
  props: {
    notifOptions: {
      type: Object,
      default: function _default() {}
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      title: "",
      auto: true,
      employeeVisible: false,
      roleVisible: false,
      defaultEmployeeKeys: [],
      defaultRoleKeys: [],
      choseEmployees: [],
      // 选中的成员
      choseRoles: [],
      copyOptions: {},
      options: {}
    };
  },
  computed: {},
  watch: {
    show: function show(val) {
      if (val) {
        this.copyOptions = JSON.parse(JSON.stringify(this.notifOptions));
        console.log("copyOptions", this.copyOptions);
        this.initOptions(this.notifOptions);
      }
    }
  },
  created: function created() {
    this.initOptions(this.notifOptions);
  },
  mounted: function mounted() {},
  methods: {
    initOptions: function initOptions(val) {
      this.options = Object.assign({}, val, {
        props: isJson(val.props) ? JSON.parse(val.props) : val.props
      });

      if (!!this.options.userIds) {
        //  this.defaultEmployeeKeys = this.options.userIds.split(',')
        this.defaultEmployeeKeys = this.options.props.employeeList;
        this.choseEmployees = this.options.props.employeeList;
      }

      if (!!this.options.roles) {
        // this.defaultRoleKeys = this.options.roles.split(',')
        this.defaultRoleKeys = this.options.props.roleList;
        this.choseRoles = this.options.props.roleList;
      }
    },
    onEmployeeChange: function onEmployeeChange(data) {
      this.defaultEmployeeKeys = data;
      this.choseEmployees = data;
      this.options.userIds = this.choseEmployees.map(function (item) {
        return item.id;
      }).toString();
      this.options.props.employeeList = this.choseEmployees;
    },
    onRoleChange: function onRoleChange(data) {
      this.defaultRoleKeys = data;
      this.choseRoles = data;
      this.options.roles = this.choseRoles.map(function (item) {
        return item.id;
      }).toString();
      this.options.props.roleList = this.choseRoles;
    },
    addEmployee: function addEmployee() {
      this.title = "成员";
      this.employeeVisible = true;
    },
    addRole: function addRole() {
      this.title = "角色";
      this.roleVisible = true;
    },
    onClose: function onClose() {
      this.$emit("update:show", false);
      this.$emit("change", this.copyOptions);
    },
    onOk: function onOk() {
      this.options.props.name = [].concat(this.choseRoles, this.choseEmployees).map(function (item) {
        return item.name;
      }).toString();
      this.$emit("update:show", false);
      this.$emit("change", this.options);
    }
  }
};