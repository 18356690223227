import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.find-index";
import "core-js/modules/es.array.includes";
import "core-js/modules/es.array.join";
import "core-js/modules/es.array.map";
import "core-js/modules/es.array.slice";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.includes";
import "core-js/modules/es.string.split";
import _interopRequireWildcard from "/data/jenkins/workspace/prod-e-retail/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard";
import Layout from "../layout";
import menus from "./menu";
var AuthRouter = {
  path: "/auth",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("xqjr-module-auth/layout/index.vue"));
    });
  },
  children: [{
    path: "/",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("xqjr-module-auth/layout/wrapper.vue"));
      });
    },
    children: [{
      path: "login",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-auth/views/login.vue"));
        });
      }
    }, {
      path: "forget",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-auth/views/forget.vue"));
        });
      }
    }]
  }]
};
var ConfigRouter = {
  path: "/config",
  component: Layout,
  children: [{
    path: "user",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("xqjr-module-config/views/user"));
      });
    }
  }, {
    path: "role",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("xqjr-module-config/views/role"));
      });
    }
  }, {
    path: "/newApproval",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/syetm/approval/new-approval.vue"));
      });
    }
  }, {
    path: "/Customer/:id/:orderInfoId/:userApproId/:userApproverId/:status",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/Customer/detail/index"));
      });
    }
  }, {
    path: "/report",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/BigData/RiskQuery/report"));
      });
    }
  }, {
    path: "/allForm",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/syetm/approval/new-approval"));
      });
    }
  }]
};
var ConsoleRouter = {
  path: "/console",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("xqjr-module-console/layout/index.vue"));
    });
  },
  children: [{
    path: "",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("xqjr-module-console/layout/admin.vue"));
      });
    },
    children: [{
      path: "",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-console/views/index"));
        });
      }
    }]
  }, {
    path: "initSite",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("xqjr-module-console/views/initSite.vue"));
      });
    }
  }, {
    path: "system",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("xqjr-module-console/layout/admin.vue"));
      });
    },
    children: [{
      path: "",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-console/views/system/index"));
        });
      }
    }, {
      path: "moduleDetail",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-console/views/system/moduleDetail"));
        });
      }
    }, {
      path: "configRoleAuth",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-console/views/system/configRoleAuth"));
        });
      }
    } // {
    //   path: "/form-set/:id?",
    //   component: () =>
    //     import(
    //       "xqjr-module-console/views/system/components/approval/form-list.vue"
    //     )
    // }
    ]
  }, {
    path: "site",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("xqjr-module-console/layout/admin.vue"));
      });
    },
    children: [{
      path: "",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-console/views/site/index"));
        });
      }
    }, {
      path: "checkSite",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-console/views/site/checkSite"));
        });
      }
    }, {
      path: "configRole",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-console/views/site/configRole"));
        });
      }
    }]
  }, {
    path: "siteUsers",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("xqjr-module-console/layout/admin.vue"));
      });
    },
    children: [{
      path: "",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-console/views/siteUsers/index"));
        });
      }
    }, {
      path: "/detail",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-console/views/siteUsers/detail"));
        });
      }
    }]
  }, {
    path: "document",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("xqjr-module-console/layout/admin.vue"));
      });
    },
    children: [{
      path: "",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-console/views/document/index"));
        });
      }
    }]
  }, {
    path: "weixinTemplate",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("xqjr-module-console/layout/admin.vue"));
      });
    },
    children: [{
      path: "",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-console/views/weixinTemplate/index"));
        });
      }
    }]
  }, {
    path: "appConfig",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("xqjr-module-console/layout/admin.vue"));
      });
    },
    children: [{
      path: "",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-console/views/appConfig/index"));
        });
      }
    }]
  }, {
    path: "log",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("xqjr-module-console/layout/admin.vue"));
      });
    },
    children: [{
      path: "actionLog",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-console/views/log/actionLog/index"));
        });
      }
    }, {
      path: "serviceLog",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-console/views/log/serviceLog/index"));
        });
      }
    }]
  }, {
    path: "version",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("xqjr-module-console/layout/admin.vue"));
      });
    },
    children: [{
      path: "app",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-console/views/version/app"));
        });
      }
    }, {
      path: "web",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-console/views/version/web"));
        });
      }
    }, {
      path: "index",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-console/views/version/index"));
        });
      }
    }]
  }, {
    path: "settings",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("xqjr-module-console/layout/admin.vue"));
      });
    },
    children: [{
      path: "",
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("xqjr-module-console/views/settings/index.vue"));
        });
      }
    }]
  }]
};
var menuComponentMap = {};
var otherRoutes = [];
var menuTree = buildMenuTree(menus);
menuTree = buildRoutes(otherRoutes, menuTree);

if (process.env.VUE_APP_SWITCH_MODULE_AUTH === "true") {
  menuTree = menuTree.concat(AuthRouter);
}

if (process.env.VUE_APP_SWITCH_MODULE_CONFIG === "true") {
  menuTree = menuTree.concat(ConfigRouter);
}

if (process.env.VUE_APP_SWITCH_MODULE_CONSOLE === "true") {
  menuTree = menuTree.concat(ConsoleRouter);
}

export default menuTree.concat([AuthRouter, {
  path: "/",
  redirect: "dashboard"
}, {
  path: "/tools",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/layout/tools"));
    });
  },
  children: [{
    path: "form",
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/tools/form"));
      });
    }
  }]
}, {
  path: "/404",
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require("@/views/404"));
    });
  }
}, {
  path: "*",
  redirect: "/404",
  hidden: true
}]);

function buildMenuTree(data) {
  var tree = transMenuArrToTree(data, 0);
  return tree;
}

function transMenuArrToTree(data, parentId) {
  var result = [];
  var temp = null;

  var _loop = function _loop(i) {
    if (data[i].parentId === parentId && !data[i].isBreak) {
      var obj = null;

      if (parentId === 0) {
        // 如果存在次级菜单
        if (data.filter(function (it) {
          return it.parentId === data[i]["id"];
        }).length > 0) {
          obj = Object.assign({}, data[i], {
            path: data[i]["url"],
            component: Layout
          });
        } else {
          obj = Object.assign({}, data[i], {
            path: data[i]["url"],
            component: Layout,
            children: [{
              path: "",
              url: data[i]["url"] + "/",
              component: menuComponentMap[data[i]["url"]] ? menuComponentMap[data[i]["url"]] : function (resolve) {
                return require(["@/views".concat(data[i]["url"])], resolve);
              }
            }]
          });
        }
      } else {
        obj = Object.assign({}, data[i], {
          path: data[i]["url"],
          component: menuComponentMap[data[i]["url"]] ? menuComponentMap[data[i]["url"]] : function (resolve) {
            return require(["@/views".concat(data[i]["url"])], resolve);
          }
        });
      }

      temp = transMenuArrToTree(data, data[i].id);

      if (temp.length > 0) {
        obj.children = temp;
      }

      result.push(obj);
    }
  };

  for (var i = 0; i < data.length; i++) {
    _loop(i);
  }

  return result;
}

function buildRoutes(routes, routeTree) {
  if (!routes || routes.length < 1) {
    return routeTree;
  }

  routes.map(function (item) {
    findParent(item, routeTree);
  });
  return routeTree;
}

function findParent(route, routeTree) {
  var paths = routeTree.map(function (item) {
    return item.url.split("/").slice(1).join("/");
  });
  var idx = paths.findIndex(function (p) {
    return route.parent === p;
  });

  if (idx > -1) {
    if (!routeTree[idx]["children"]) {
      routeTree[idx]["children"] = [];
    }

    routeTree[idx]["children"].push(Object.assign({
      url: routeTree[idx]["url"] + route.url
    }, route));
  } else {
    var hazyIdx = paths.findIndex(function (p) {
      return route.parent.split("/").includes(p.split("/")[0]);
    });

    if (hazyIdx > -1) {
      if (!routeTree[hazyIdx]["children"]) {
        routeTree[hazyIdx]["children"] = [];
      }

      findParent(route, routeTree[hazyIdx]["children"]);
    }
  }
}