import "core-js/modules/es.array.filter";
import "core-js/modules/es.function.name";
import _objectSpread from "/data/jenkins/workspace/prod-e-retail/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
import _logo from "@/assets/images/logo.png";
import { mapState, mapGetters } from "vuex";
export default {
  props: {
    collapsed: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {};
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState(["settings"])), mapGetters(["permissions_site"])), {}, {
    sysName: function sysName() {
      var currentSite = this.permissions_site.filter(function (item) {
        return item.currSite === true;
      });

      if (currentSite.length > 0 && !!currentSite[0]["name"]) {
        return currentSite[0]["name"];
      }

      return this.settings.system_title;
    },
    logo: function logo() {
      var currentSite = this.permissions_site.filter(function (item) {
        return item.currSite === true;
      });

      if (currentSite.length > 0 && !!currentSite[0]["pic"]) {
        return currentSite[0]["pic"];
      }

      return _logo;
    }
  })
};