import request from "@/utils/request"; // 拿到所有成员

export function users(params) {
  return request({
    url: "/finance/api/v1/channelMembers/listAll",
    method: "GET",
    params: params
  });
} // 拿到所有角色

export function roles(params) {
  return request({
    url: "/permission/api/v1/roles",
    method: "GET",
    params: params
  });
}