var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-form",
    [
      _c(
        "a-spin",
        { staticClass: "xq-auto-wrapper", attrs: { spinning: _vm.spinning } },
        [
          _c(
            "div",
            { staticClass: "xq-auto-search" },
            [
              _c("search-form", {
                attrs: { sourceForm: _vm.RecordForm },
                on: { search: _vm.filterRecord }
              })
            ],
            1
          ),
          _c("div", { staticClass: "center" }, [
            _c(
              "div",
              { staticClass: "xq-auto-content" },
              [
                _c(
                  "a-table",
                  {
                    staticClass: "xq-auto-content-table",
                    attrs: {
                      columns: _vm.columns,
                      "data-source": _vm.tableData,
                      pagination: _vm.pagination,
                      rowKey: "id"
                    },
                    on: { change: _vm.onTableChange },
                    scopedSlots: _vm._u(
                      [
                        _vm._l(_vm.columns, function(col, index) {
                          return {
                            key: col.dataIndex,
                            fn: function(text, record) {
                              return [
                                col.dataIndex === "auditStatus"
                                  ? _c("span", { key: index }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            record.auditStatus == 2
                                              ? "已同意"
                                              : "已拒绝"
                                          ) +
                                          " "
                                      )
                                    ])
                                  : col.dataIndex === "gmtCreate"
                                  ? _c("span", { key: index }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("formatDate")(
                                              record.gmtCreate,
                                              "YYYY-MM-DD HH:mm:ss"
                                            )
                                          ) +
                                          " "
                                      )
                                    ])
                                  : col.dataIndex === "crudAction"
                                  ? _c("span", { key: index }, [
                                      _c(
                                        "a",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.statusDetail(record)
                                            }
                                          }
                                        },
                                        [_vm._v("详情")]
                                      )
                                    ])
                                  : _c("table-cell", {
                                      key: index,
                                      attrs: {
                                        text: text,
                                        record: record,
                                        column: col,
                                        dynamicData: _vm.dynamicData
                                      }
                                    })
                              ]
                            }
                          }
                        })
                      ],
                      null,
                      true
                    )
                  },
                  [
                    _vm._l(_vm.columns, function(col, index) {
                      return _c(
                        "template",
                        { slot: "custom_" + col.dataIndex },
                        [
                          _c(
                            "span",
                            { key: index },
                            [
                              col.help
                                ? _c(
                                    "a-tooltip",
                                    { attrs: { title: col.help } },
                                    [
                                      _c("a-icon", {
                                        staticStyle: { "margin-right": "5px" },
                                        attrs: { type: "question-circle" }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" " + _vm._s(col.customTitle) + " ")
                            ],
                            1
                          )
                        ]
                      )
                    })
                  ],
                  2
                ),
                _c(
                  "div",
                  { staticClass: "xq-auto-content-pagination" },
                  [
                    _c(
                      "div",
                      { staticClass: "xq-auto-content-pagination-total" },
                      [_vm._v("共 " + _vm._s(_vm.total) + " 条")]
                    ),
                    _c("a-pagination", {
                      attrs: {
                        pageSize: _vm.pageSize,
                        total: _vm.total,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ["10", "20", "50"]
                      },
                      on: {
                        change: _vm.onPageChange,
                        showSizeChange: _vm.onPageSizeChange
                      },
                      model: {
                        value: _vm.currentPage,
                        callback: function($$v) {
                          _vm.currentPage = $$v
                        },
                        expression: "currentPage"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _vm.jsonData
            ? _c("k-form-build", {
                ref: "kfb",
                attrs: { value: _vm.jsonData, dynamicData: _vm.dynamicData },
                on: {
                  change: function($event) {
                    return _vm.onFormChange(arguments[0], arguments[1], "kfb")
                  }
                }
              })
            : _vm._e(),
          _c(
            "a-modal",
            {
              attrs: { width: "80%", visible: _vm.confirmAlert, footer: "" },
              on: {
                cancel: function($event) {
                  _vm.confirmAlert = false
                }
              }
            },
            [
              _c("Baseinfo", {
                staticStyle: { "margin-top": "30px" },
                attrs: { status: _vm.status, productId: _vm.productId }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }