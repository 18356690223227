export default {
  fields: {
    customerVO: {
      model: "customerVO",
      type: "object",
      extend: {
        fields: {
          name: {
            model: "name",
            type: "input",
            wrapper: {
              label: "客户或企业名称"
            },
            customize: {
              allowClear: true,
              placeholder: "请输入客户或企业名称"
            },
            decorator: {
              initialValue: undefined,
              rules: [{
                required: true,
                message: "必填项"
              }]
            }
          },
          sex: {
            model: "sex",
            type: "select",
            wrapper: {
              label: "性别"
            },
            customize: {
              allowClear: true,
              placeholder: "请选择性别"
            },
            decorator: {
              initialValue: null
            },
            extend: {
              dynamic: true,
              dynamicKey: "sexList"
            }
          },
          phoneSet: {
            model: "phoneSet",
            type: "number",
            wrapper: {
              label: "联系电话(座机)"
            },
            customize: {
              min: 0,
              allowClear: true,
              placeholder: "请输入联系电话(座机)"
            },
            decorator: {
              initialValue: ""
            }
          },
          phone: {
            model: "phone",
            type: "input",
            wrapper: {
              label: "联系电话"
            },
            customize: {
              allowClear: true,
              placeholder: "请输入联系电话"
            },
            decorator: {
              initialValue: "",
              rules: [{
                required: true,
                message: "必填项"
              }, {
                pattern: "^1[3-9]\\d{9}$",
                message: "手机号码格式错误"
              }]
            }
          },
          email: {
            model: "email",
            type: "input",
            wrapper: {
              label: "邮箱"
            },
            customize: {
              allowClear: true,
              placeholder: "请输入邮箱"
            },
            decorator: {
              initialValue: undefined,
              rules: [{
                required: false,
                message: "必填项"
              }]
            }
          },
          idCard: {
            model: "idCard",
            type: "input",
            wrapper: {
              label: "证件号码"
            },
            customize: {
              allowClear: true,
              placeholder: "请输入证件号码"
            },
            decorator: {
              initialValue: "",
              rules: [{
                required: true,
                message: "必填项"
              }, {
                pattern: "^[1-9]\\d{5}(18|19|20)\\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\\d{3}[0-9Xx]$",
                message: "身份证号格式错误"
              }]
            }
          },
          idCardType: {
            model: "idCardType",
            type: "select",
            wrapper: {
              label: "证件种类"
            },
            customize: {
              placeholder: "请选择证件种类",
              options: [{
                value: "MAINLAND",
                label: "身份证"
              }]
            },
            decorator: {
              initialValue: "MAINLAND"
            }
          },
          marriedStatus: {
            model: "marriedStatus",
            type: "select",
            wrapper: {
              label: "婚姻状况"
            },
            customize: {
              allowClear: true,
              placeholder: "请选择婚姻状况",
              options: [{
                value: 1,
                label: "未婚"
              }, {
                value: 2,
                label: "已婚"
              }, {
                value: 3,
                label: "离异"
              }, {
                value: 4,
                label: "丧偶"
              }]
            },
            decorator: {
              initialValue: null
            }
          },
          age: {
            model: "age",
            type: "number",
            wrapper: {
              label: "年龄"
            },
            customize: {
              min: 0,
              allowClear: true,
              placeholder: "请输入年龄"
            },
            decorator: {
              rules: [{
                required: true,
                message: "必填项"
              }]
            }
          },
          childrenNum: {
            model: "childrenNum",
            type: "number",
            wrapper: {
              label: "子女人数"
            },
            customize: {
              min: 0,
              allowClear: true,
              placeholder: "请输入子女人数"
            },
            decorator: {
              initialValue: undefined,
              rules: [{
                required: false,
                message: "必填项"
              }]
            }
          },
          householdsNum: {
            model: "householdsNum",
            type: "number",
            wrapper: {
              label: "家庭人口"
            },
            customize: {
              min: 0,
              allowClear: true,
              placeholder: "请输入家庭人口",
              suffix: "人"
            },
            decorator: {
              initialValue: ""
            }
          },
          houseWorkNumber: {
            model: "houseWorkNumber",
            type: "number",
            wrapper: {
              label: "劳动力人口"
            },
            customize: {
              min: 0,
              allowClear: true,
              placeholder: "请输入劳动力人口",
              suffix: "人"
            },
            decorator: {
              initialValue: "",
              rules: [{
                required: false,
                message: "必填项"
              }]
            }
          },
          educationLevel: {
            model: "educationLevel",
            type: "select",
            wrapper: {
              label: "最高学历"
            },
            customize: {
              allowClear: true,
              placeholder: "请选择最高学历"
            },
            decorator: {
              initialValue: undefined
            },
            extend: {
              dynamic: true,
              dynamicKey: "educationLevelList"
            }
          },
          addressId: {
            model: "addressId",
            type: "input",
            wrapper: {
              label: "地址"
            },
            customize: {
              allowClear: true,
              placeholder: "请输入地址"
            },
            decorator: {
              initialValue: undefined,
              rules: [{
                required: false,
                message: "必填项"
              }]
            }
          },
          address: {
            model: "address",
            type: "input",
            wrapper: {
              label: "户籍地址"
            },
            customize: {
              allowClear: true,
              placeholder: "请输入户籍地址"
            },
            decorator: {
              initialValue: undefined,
              rules: [{
                required: false,
                message: "必填项"
              }]
            }
          },
          addressDetail: {
            model: "addressDetail",
            type: "input",
            wrapper: {
              label: "户籍企业注册地址详情"
            },
            customize: {
              allowClear: true,
              placeholder: "请输入户籍企业注册地址详情"
            },
            decorator: {
              initialValue: undefined,
              rules: [{
                required: false,
                message: "必填项"
              }]
            }
          },
          currentAddressId: {
            model: "currentAddressId",
            type: "area",
            wrapper: {
              label: "居住地址"
            },
            customize: {
              allowClear: true,
              multiple: false,
              placeholder: "请选择居住地址"
            },
            decorator: {
              initialValue: undefined
            },
            extend: {
              level: 3
            }
          },
          currentAddress: {
            model: "currentAddress",
            type: "area",
            wrapper: {
              label: "现居住地或企业办公地址"
            },
            customize: {
              allowClear: true,
              multiple: false,
              placeholder: "现居住地或企业办公地址"
            },
            decorator: {
              initialValue: undefined
            },
            extend: {
              level: 3
            }
          },
          currentAddressDetail: {
            model: "currentAddressDetail",
            type: "input",
            wrapper: {
              label: "详细居住地址"
            },
            customize: {
              allowClear: true,
              placeholder: "请输入详细居住地址"
            },
            decorator: {
              initialValue: ""
            }
          },
          registerTime: {
            model: "registerTime",
            type: "date",
            wrapper: {
              label: "企业注册时间"
            },
            customize: {
              allowClear: true,
              placeholder: "请输入企业注册时间",
              format: "YYYY-MM-DD"
            },
            decorator: {
              initialValue: ""
            }
          },
          registerAmount: {
            model: "registerAmount",
            type: "input",
            wrapper: {
              label: "注册资本"
            },
            customize: {
              allowClear: true,
              placeholder: "请输入注册资本"
            },
            decorator: {
              initialValue: ""
            }
          },
          companyContact: {
            model: "companyContact",
            type: "input",
            wrapper: {
              label: "企业联系人"
            },
            customize: {
              allowClear: true,
              placeholder: "请输入企业联系人"
            },
            decorator: {
              initialValue: ""
            }
          },
          loanCard: {
            model: "loanCard",
            type: "number",
            wrapper: {
              label: "贷款卡号"
            },
            customize: {
              min: 0,
              allowClear: true,
              placeholder: "请输入贷款卡号"
            },
            decorator: {
              initialValue: ""
            }
          },
          position: {
            model: "position",
            type: "select",
            wrapper: {
              label: "职位"
            },
            customize: {
              allowClear: true,
              placeholder: "请输入职位",
              options: [{
                value: 1,
                label: "法人"
              }, {
                value: 2,
                label: "股东"
              }, {
                value: 3,
                label: "高级领导"
              }, {
                value: 4,
                label: "中级领导"
              }, {
                value: 5,
                label: "一般员工"
              }, {
                value: 6,
                label: "其他"
              }]
            },
            decorator: {
              initialValue: null
            }
          },
          currentBankAccount: {
            model: "currentBankAccount",
            type: "number",
            wrapper: {
              label: "本行账户"
            },
            customize: {
              min: 0,
              allowClear: true,
              placeholder: "请输入本行账户"
            },
            decorator: {
              initialValue: ""
            }
          },
          health: {
            model: "health",
            type: "autoComplete",
            wrapper: {
              label: "身体状况"
            },
            customize: {
              placeholder: "请选择或输入身体状况",
              filterOption: true,
              dataSource: [{
                value: "健康",
                text: "健康"
              }]
            },
            decorator: {
              initialValue: "健康"
            }
          },
          remark: {
            model: "remark",
            type: "input",
            wrapper: {
              label: "备注"
            },
            customize: {
              allowClear: true,
              placeholder: "请输入备注"
            },
            decorator: {
              initialValue: ""
            }
          },
          customerIncome: {
            model: "customerIncome",
            type: "number",
            wrapper: {
              label: "借款人年收入"
            },
            customize: {
              min: 0,
              allowClear: true,
              placeholder: "请输入借款人年收入",
              suffix: "元"
            },
            decorator: {
              initialValue: ""
            }
          },
          familyIncome: {
            model: "familyIncome",
            type: "number",
            wrapper: {
              label: "家庭年收入"
            },
            customize: {
              min: 0,
              allowClear: true,
              placeholder: "请输入家庭年收入",
              suffix: "元"
            },
            decorator: {
              initialValue: ""
            }
          }
        }
      }
    },
    customerOccupationVO: {
      model: "customerOccupationVO",
      type: "object",
      extend: {
        fields: {
          companyName: {
            model: "companyName",
            type: "select",
            wrapper: {
              label: "工作单位"
            },
            customize: {
              allowClear: true,
              placeholder: "请输入工作单位",
              options: [{
                value: "1",
                label: "自雇人士"
              }, {
                value: "2",
                label: "受薪人士"
              }]
            },
            decorator: {
              initialValue: null
            }
          },
          enterprisePosition: {
            model: "enterprisePosition",
            type: "select",
            wrapper: {
              label: "职位"
            },
            customize: {
              allowClear: true,
              placeholder: "请输入职位",
              options: [{
                value: 1,
                label: "法人"
              }, {
                value: 2,
                label: "股东"
              }, {
                value: 3,
                label: "高级领导"
              }, {
                value: 4,
                label: "中级领导"
              }, {
                value: 5,
                label: "一般员工"
              }, {
                value: 6,
                label: "其他"
              }]
            },
            decorator: {
              initialValue: null
            }
          }
        }
      }
    },
    creditList: {
      model: "creditList",
      type: "table",
      customize: {
        rowKey: "id"
      },
      extend: {
        fields: {
          bankName: {
            model: "bankName",
            type: "input",
            wrapper: {
              label: "借款银行"
            },
            customize: {
              allowClear: true,
              placeholder: "请输入借款银行"
            },
            decorator: {
              initialValue: "",
              rules: [{
                required: true,
                message: "必填项"
              }]
            }
          },
          loanNum: {
            model: "loanNum",
            type: "input",
            wrapper: {
              label: "借款金额"
            },
            customize: {
              allowClear: true,
              placeholder: "请输入借款金额",
              suffix: "元"
            },
            decorator: {
              initialValue: "",
              rules: [{
                required: true,
                message: "必填项"
              }]
            }
          },
          leftNum: {
            model: "leftNum",
            type: "input",
            wrapper: {
              label: "剩余金额"
            },
            customize: {
              allowClear: true,
              placeholder: "请输入剩余金额",
              suffix: "元"
            },
            decorator: {
              initialValue: "",
              rules: [{
                required: true,
                message: "必填项"
              }]
            }
          },
          endDate: {
            model: "endDate",
            type: "date",
            wrapper: {
              label: "到期日"
            },
            customize: {
              allowClear: true,
              placeholder: "请选择到期日",
              format: "YYYY-MM-DD"
            },
            decorator: {
              initialValue: null,
              rules: [{
                required: true,
                message: "必填项"
              }]
            }
          },
          guaranteeType: {
            model: "guaranteeType",
            type: "input",
            wrapper: {
              label: "担保方式"
            },
            customize: {
              allowClear: true,
              placeholder: "请输入担保方式"
            },
            decorator: {
              initialValue: "",
              rules: [{
                required: true,
                message: "必填项"
              }]
            }
          }
        }
      }
    },
    creditExtend: {
      model: "creditExtend",
      type: "textarea",
      wrapper: {
        label: "信用状况备注信息",
        labelCol: {
          span: 3
        },
        wrapperCol: {
          span: 21
        }
      },
      customize: {
        allowClear: true,
        placeholder: "请输入信用状况备注信息"
      },
      decorator: {
        initialValue: "",
        rules: [{
          required: false,
          message: "必填项"
        }]
      }
    },
    spouseInfoAddDTO_name: {
      model: "spouseInfoAddDTO_name",
      type: "input",
      wrapper: {
        label: "姓名"
      },
      customize: {
        allowClear: true,
        placeholder: "请输入姓名"
      },
      decorator: {
        rules: [{
          required: true,
          message: "必填项"
        }]
      }
    },
    spouseInfoVO: {
      model: "spouseInfoVO",
      type: "object",
      extend: {
        fields: {
          name: {
            model: "name",
            type: "input",
            wrapper: {
              label: "姓名"
            },
            customize: {
              allowClear: true,
              placeholder: ""
            },
            decorator: {
              initialValue: "",
              rules: [{
                required: true,
                message: "必填项"
              }]
            }
          },
          sex: {
            model: "sex",
            type: "select",
            wrapper: {
              label: "性别"
            },
            customize: {
              allowClear: true,
              placeholder: "请选择性别",
              options: [{
                value: 1,
                label: "男"
              }, {
                value: 2,
                label: "女"
              }]
            },
            decorator: {
              initialValue: ""
            }
          },
          idCardType: {
            model: "idCardType",
            type: "select",
            wrapper: {
              label: "证件种类"
            },
            customize: {
              placeholder: "请选择证件种类",
              options: [{
                value: "1",
                label: "身份证"
              }]
            },
            decorator: {
              initialValue: ""
            }
          },
          idCard: {
            model: "idCard",
            type: "input",
            wrapper: {
              label: "证件号码"
            },
            customize: {
              allowClear: true,
              placeholder: "请输入证件号码"
            },
            decorator: {
              initialValue: "",
              rules: [{
                required: true,
                message: "必填项"
              }, {
                pattern: "^[1-9]\\d{5}(18|19|20)\\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\\d{3}[0-9Xx]$",
                message: "身份证号格式错误"
              }]
            }
          },
          educationLevel: {
            model: "educationLevel",
            type: "select",
            wrapper: {
              label: "最高学历"
            },
            customize: {
              allowClear: true,
              placeholder: "请选择最高学历",
              options: [{
                value: 1,
                label: "初中及以下"
              }, {
                value: 2,
                label: "高中及中等专业学校"
              }, {
                value: 3,
                label: "大专"
              }, {
                value: 4,
                label: "本科"
              }, {
                value: 5,
                label: "硕士"
              }, {
                value: 6,
                label: "博士及以上"
              }]
            },
            decorator: {
              initialValue: undefined
            }
          },
          spouseInfoAddDTO_yearlyIncome: {
            model: "yearlyIncome",
            type: "input",
            wrapper: {
              label: "个人年收入"
            },
            customize: {
              allowClear: true,
              placeholder: "请输入个人年收入",
              suffix: "元"
            },
            decorator: {
              initialValue: ""
            }
          },
          currentBankAccount: {
            model: "currentBankAccount",
            type: "input",
            wrapper: {
              label: "账户账号"
            },
            customize: {
              allowClear: true,
              placeholder: "请输入账户账号",
              suffix: "元"
            },
            decorator: {
              initialValue: ""
            }
          },
          health: {
            model: "health",
            type: "autoComplete",
            wrapper: {
              label: "身体状况"
            },
            customize: {
              placeholder: "请选择或输入身体状况",
              filterOption: true,
              dataSource: [{
                value: "健康",
                text: "健康"
              }]
            },
            decorator: {
              initialValue: "健康"
            }
          },
          companyName: {
            model: "companyName",
            type: "input",
            wrapper: {
              label: "工作单位"
            },
            customize: {
              allowClear: true,
              placeholder: "请输入工作单位"
            },
            decorator: {
              initialValue: ""
            }
          },
          position: {
            model: "position",
            type: "select",
            wrapper: {
              label: "职位"
            },
            customize: {
              allowClear: true,
              placeholder: "请输入职位",
              options: [{
                value: 1,
                label: "法人"
              }, {
                value: 2,
                label: "股东"
              }, {
                value: 3,
                label: "高级领导"
              }, {
                value: 4,
                label: "中级领导"
              }, {
                value: 5,
                label: "一般员工"
              }, {
                value: 6,
                label: "其他"
              }]
            },
            decorator: {
              initialValue: ""
            }
          },
          phone: {
            model: "phone",
            type: "input",
            wrapper: {
              label: "联系电话"
            },
            customize: {
              allowClear: true,
              placeholder: "请输入联系电话"
            },
            decorator: {
              initialValue: "",
              rules: [{
                required: true,
                message: "必填项"
              }, {
                pattern: "^1[3-9]\\d{9}$",
                message: "手机号码格式错误"
              }]
            }
          },
          extend: {
            model: "extend",
            type: "textarea",
            wrapper: {
              label: "备注信息"
            },
            customize: {
              allowClear: true,
              placeholder: "请输入备注信息"
            },
            decorator: {
              initialValue: ""
            }
          }
        }
      }
    },
    spouseCreditList: {
      model: "spouseCreditList",
      type: "table",
      customize: {
        rowKey: "id"
      },
      extend: {
        fields: {
          bankName: {
            model: "bankName",
            type: "input",
            wrapper: {
              label: "借款银行"
            },
            customize: {
              allowClear: true,
              placeholder: "请输入借款银行"
            },
            decorator: {
              initialValue: "",
              rules: [{
                required: true,
                message: "必填项"
              }]
            }
          },
          loanNum: {
            model: "loanNum",
            type: "input",
            wrapper: {
              label: "借款金额"
            },
            customize: {
              allowClear: true,
              placeholder: "请输入借款金额",
              suffix: "元"
            },
            decorator: {
              initialValue: "",
              rules: [{
                required: true,
                message: "必填项"
              }]
            }
          },
          leftNum: {
            model: "leftNum",
            type: "input",
            wrapper: {
              label: "剩余金额"
            },
            customize: {
              allowClear: true,
              placeholder: "请输入剩余金额",
              suffix: "元"
            },
            decorator: {
              initialValue: "",
              rules: [{
                required: true,
                message: "必填项"
              }]
            }
          },
          endDate: {
            model: "endDate",
            type: "date",
            wrapper: {
              label: "到期日"
            },
            customize: {
              allowClear: true,
              placeholder: "请选择到期日",
              format: "YYYY-MM-DD"
            },
            decorator: {
              initialValue: null,
              rules: [{
                required: true,
                message: "必填项"
              }]
            }
          },
          guaranteeType: {
            model: "guaranteeType",
            type: "input",
            wrapper: {
              label: "担保方式"
            },
            customize: {
              allowClear: true,
              placeholder: "请输入担保方式"
            },
            decorator: {
              initialValue: "",
              rules: [{
                required: true,
                message: "必填项"
              }]
            }
          }
        }
      }
    },
    spouseCreditExtend: {
      model: "spouseCreditExtend",
      type: "textarea",
      wrapper: {
        label: "信用状况备注信息",
        labelCol: {
          span: 3
        },
        wrapperCol: {
          span: 21
        }
      },
      customize: {
        allowClear: true,
        placeholder: "请输入信用状况备注信息"
      },
      decorator: {
        initialValue: "",
        rules: [{
          required: false,
          message: "必填项"
        }]
      }
    },
    assetList: {
      model: "assetList",
      type: "table",
      customize: {
        rowKey: "id"
      },
      extend: {
        fields: {
          assetName: {
            model: "assetName",
            type: "input",
            wrapper: {
              label: "产权人"
            },
            customize: {
              allowClear: true,
              placeholder: "请输入产权人"
            },
            decorator: {
              initialValue: "",
              rules: [{
                required: true,
                message: "必填项"
              }]
            }
          },
          location: {
            model: "location",
            type: "input",
            wrapper: {
              label: "位置"
            },
            customize: {
              allowClear: true,
              placeholder: "请输入位置"
            },
            decorator: {
              initialValue: ""
            }
          },
          assetNo: {
            model: "assetNo",
            type: "input",
            wrapper: {
              label: "产权证号"
            },
            customize: {
              allowClear: true,
              placeholder: "请输入产权证号"
            },
            decorator: {
              initialValue: "",
              rules: [{
                required: true,
                message: "必填项"
              }]
            }
          },
          area: {
            model: "area",
            type: "input",
            wrapper: {
              label: "面积"
            },
            customize: {
              allowClear: true,
              placeholder: "请输入面积"
            },
            decorator: {
              initialValue: ""
            }
          },
          status: {
            model: "status",
            type: "input",
            wrapper: {
              label: "目前状态"
            },
            customize: {
              allowClear: true,
              placeholder: "请输入"
            },
            decorator: {
              initialValue: ""
            }
          }
        }
      }
    },
    assetExtend: {
      model: "assetExtend",
      type: "textarea",
      wrapper: {
        label: "资产信息备注信息",
        labelCol: {
          span: 3
        },
        wrapperCol: {
          span: 21
        }
      },
      customize: {
        allowClear: true,
        placeholder: "请输入资产信息备注信息"
      },
      decorator: {
        initialValue: "",
        rules: [{
          required: false,
          message: "必填项"
        }]
      }
    },
    operateVO: {
      model: "operateVO",
      type: "object",
      extend: {
        fields: {
          name: {
            model: "name",
            type: "input",
            wrapper: {
              label: "全称"
            },
            customize: {
              allowClear: true,
              placeholder: "请输入全称"
            },
            decorator: {
              initialValue: ""
            }
          },
          certCheckDate: {
            model: "certCheckDate",
            type: "date",
            wrapper: {
              label: "证件年检时间"
            },
            customize: {
              allowClear: true,
              placeholder: "请选择证件年检时间",
              format: "YYYY-MM-DD"
            },
            decorator: {
              initialValue: null
            }
          },
          type: {
            model: "type",
            type: "select",
            wrapper: {
              label: "客户类别"
            },
            customize: {
              allowClear: true,
              placeholder: "请选择客户类别",
              options: [{
                value: 1,
                label: "党政及国家机关"
              }, {
                value: 2,
                label: "事业单位"
              }, {
                value: 3,
                label: "民办非企业单位（有限公司）"
              }, {
                value: 4,
                label: "社会团体"
              }, {
                value: 5,
                label: "个体工商户"
              }]
            },
            decorator: {
              initialValue: ""
            }
          },
          operateTime: {
            model: "operateTime",
            type: "input",
            wrapper: {
              label: "经营期限"
            },
            customize: {
              allowClear: true,
              placeholder: "请输入经营期限"
            },
            decorator: {
              initialValue: ""
            }
          },
          people: {
            model: "people",
            type: "input",
            wrapper: {
              label: "企业人数"
            },
            customize: {
              allowClear: true,
              placeholder: "请输入企业人数"
            },
            decorator: {
              initialValue: ""
            }
          },
          registCapital: {
            model: "registCapital",
            type: "input",
            wrapper: {
              label: "注册资本"
            },
            customize: {
              allowClear: true,
              placeholder: "请输入注册资本"
            },
            decorator: {
              initialValue: ""
            }
          },
          registLocation: {
            model: "registLocation",
            type: "input",
            wrapper: {
              label: "注册地址"
            },
            customize: {
              allowClear: true,
              placeholder: "请输入注册地址"
            },
            decorator: {
              initialValue: ""
            }
          },
          realLocation: {
            model: "realLocation",
            type: "input",
            wrapper: {
              label: "实际经营地址"
            },
            customize: {
              allowClear: true,
              placeholder: "请输入实际经营地址"
            },
            decorator: {
              initialValue: ""
            }
          },
          operateName: {
            model: "operateName",
            type: "input",
            wrapper: {
              label: "经营者姓名"
            },
            customize: {
              allowClear: true,
              placeholder: "请输入经营者姓名"
            },
            decorator: {
              initialValue: ""
            }
          },
          legalPerson: {
            model: "legalPerson",
            type: "input",
            wrapper: {
              label: "法定代表人"
            },
            customize: {
              allowClear: true,
              placeholder: "请输入法定代表人"
            },
            decorator: {
              initialValue: ""
            }
          },
          operateProduct: {
            model: "operateProduct",
            type: "input",
            wrapper: {
              label: "经营范围"
            },
            customize: {
              allowClear: true,
              placeholder: "请输入经营范围"
            },
            decorator: {
              initialValue: ""
            }
          },
          mainProduct: {
            model: "mainProduct",
            type: "input",
            wrapper: {
              label: "主导产品"
            },
            customize: {
              allowClear: true,
              placeholder: "请输入主导产品"
            },
            decorator: {
              initialValue: ""
            }
          },
          accountName: {
            model: "accountName",
            type: "input",
            wrapper: {
              label: "账户名"
            },
            customize: {
              allowClear: true,
              placeholder: "请输入账户名"
            },
            decorator: {
              initialValue: ""
            }
          },
          accountBank: {
            model: "accountBank",
            type: "input",
            wrapper: {
              label: "开户行"
            },
            customize: {
              allowClear: true,
              placeholder: "请输入开户行"
            },
            decorator: {
              initialValue: ""
            }
          },
          accountNo: {
            model: "accountNo",
            type: "input",
            wrapper: {
              label: "账号"
            },
            customize: {
              allowClear: true,
              placeholder: "请输入账号"
            },
            decorator: {
              initialValue: ""
            }
          },
          accountBill: {
            model: "accountBill",
            type: "input",
            wrapper: {
              label: "业务流水额"
            },
            customize: {
              allowClear: true,
              placeholder: "请输入业务流水额"
            },
            decorator: {
              initialValue: ""
            }
          },
          oremark: {
            model: "remark",
            type: "textarea",
            wrapper: {
              label: "经营情况",
              labelCol: {
                span: 3
              },
              wrapperCol: {
                span: 21
              }
            },
            customize: {
              allowClear: true,
              placeholder: "请输入经营情况"
            },
            decorator: {
              initialValue: ""
            }
          },
          extend: {
            model: "extend",
            type: "textarea",
            wrapper: {
              label: "备注"
            },
            customize: {
              allowClear: true,
              placeholder: "请输入备注"
            },
            decorator: {
              initialValue: ""
            }
          }
        }
      }
    },
    // 担保物信息
    guaranteeList: {
      model: "guaranteeList",
      type: "table",
      customize: {
        rowKey: "id"
      },
      extend: {
        fields: {
          evaluationAgency: {
            model: "evaluationAgency",
            type: "input",
            wrapper: {
              label: "评估机构"
            },
            customize: {
              allowClear: true,
              placeholder: "请输入评估机构"
            },
            decorator: {
              initialValue: undefined,
              rules: [{
                required: true,
                message: "必填项"
              }]
            }
          },
          agencySatisfied: {
            model: "agencySatisfied",
            type: "select",
            wrapper: {
              label: "是否满足本次业务评估资质要求"
            },
            customize: {
              allowClear: true,
              placeholder: "请选择",
              options: [{
                value: 1,
                label: "是"
              }, {
                value: 0,
                label: "否"
              }]
            },
            decorator: {
              initialValue: undefined,
              rules: [{
                required: false,
                message: "必填项"
              }]
            }
          },
          name: {
            model: "name",
            type: "input",
            wrapper: {
              label: "抵押名称"
            },
            customize: {
              allowClear: true,
              placeholder: "请输入抵押名称",
              suffix: ""
            },
            decorator: {
              initialValue: undefined,
              rules: [{
                required: true,
                message: "必填项"
              }]
            }
          },
          addressEtc: {
            model: "addressEtc",
            type: "input",
            wrapper: {
              label: "地址和权属证号"
            },
            customize: {
              allowClear: true,
              placeholder: "地址和权属证号",
              suffix: ""
            },
            decorator: {
              initialValue: undefined,
              rules: [{
                required: true,
                message: "必填项"
              }]
            }
          },
          houseArea: {
            model: "houseArea",
            type: "input",
            wrapper: {
              label: "面积"
            },
            customize: {
              allowClear: true,
              placeholder: "请输入面积"
            },
            decorator: {
              initialValue: undefined,
              rules: [{
                required: true,
                message: "必填项"
              }]
            }
          },
          valuation: {
            model: "valuation",
            type: "input",
            wrapper: {
              label: "估值"
            },
            customize: {
              allowClear: true,
              placeholder: "请输入估值"
            },
            decorator: {
              initialValue: undefined,
              rules: [{
                required: true,
                message: "必填项"
              }]
            }
          },
          guaranteeRate: {
            model: "guaranteeRate",
            type: "input",
            wrapper: {
              label: "抵押率"
            },
            customize: {
              allowClear: true,
              placeholder: "请输入抵押率"
            },
            decorator: {
              initialValue: undefined,
              rules: [{
                required: false,
                message: "必填项"
              }]
            }
          },
          guaranteeDesc: {
            model: "guaranteeDesc",
            type: "textarea",
            wrapper: {
              label: "抵质押物说明"
            },
            customize: {
              allowClear: true,
              placeholder: "请输入"
            },
            decorator: {
              initialValue: undefined,
              rules: [{
                required: false,
                message: "必填项"
              }]
            }
          },
          guaranteeAdvantage: {
            model: "guaranteeAdvantage",
            type: "textarea",
            wrapper: {
              label: "抵质押物抵质押率说明"
            },
            customize: {
              allowClear: true,
              placeholder: "请输入"
            },
            decorator: {
              initialValue: undefined,
              rules: [{
                required: false,
                message: "必填项"
              }]
            }
          }
        },
        action: {
          width: 60
        }
      }
    },
    guaranteeExtend: {
      model: "guaranteeExtend",
      type: "textarea",
      wrapper: {
        label: "担保物备注信息",
        labelCol: {
          span: 3
        },
        wrapperCol: {
          span: 21
        }
      },
      customize: {
        allowClear: true,
        placeholder: "请输入担保物备注信息"
      },
      decorator: {
        initialValue: "",
        rules: [{
          required: false,
          message: "必填项"
        }]
      }
    }
  },
  config: {}
};