export default [{
  id: 1,
  parentId: 0,
  icon: "laugh",
  name: "首页总览",
  url: "/dashboard"
}, {
  id: 2,
  parentId: 0,
  icon: "laugh",
  name: "用户管理",
  url: "/config",
  isBreak: true
}, {
  id: 3,
  parentId: 2,
  icon: "",
  name: "用户管理",
  url: "/config/user",
  isBreak: true
}, {
  id: 55,
  parentId: 0,
  icon: "laugh",
  name: "渠道管理",
  url: "/channel"
}, {
  id: 4,
  parentId: 2,
  icon: "",
  name: "角色管理",
  url: "/config/role",
  isBreak: true
}, {
  id: 5,
  parentId: 0,
  icon: "laugh",
  name: "流程配置",
  url: "/syetm"
}, {
  id: 82,
  parentId: 5,
  icon: "",
  name: "审批设置",
  url: "/syetm/approval/all"
}, {
  id: 6,
  icon: "laugh",
  parentId: 0,
  name: "订单中心",
  url: "/order"
}, {
  id: 46,
  parentId: 6,
  icon: "laugh",
  name: "审批中",
  url: "/order/approvaling"
}, {
  id: 47,
  parentId: 6,
  icon: "laugh",
  name: "进行中",
  url: "/order/doing"
}, {
  id: 48,
  parentId: 6,
  icon: "laugh",
  name: "已完结",
  url: "/order/finished"
}, {
  id: 7,
  parentId: 0,
  icon: "laugh",
  name: "贷前管理",
  url: "/approvals"
}, {
  id: 81,
  parentId: 7,
  icon: "",
  name: "审批列表",
  url: "/approvals/index"
}, {
  id: 10,
  parentId: 0,
  icon: "laugh",
  name: "大数据",
  url: "/BigData"
}, {
  id: 8,
  parentId: 0,
  icon: "laugh",
  name: "客户管理",
  url: "/kehuguanli"
}, {
  id: 20,
  parentId: 0,
  icon: "laugh",
  name: "意向客户",
  url: "/orderMange"
}, {
  id: 21,
  parentId: 20,
  icon: "",
  name: "待办池",
  url: "/orderMange/order"
}, {
  id: 23,
  parentId: 20,
  icon: "",
  name: "回收池",
  url: "/orderMange/recovery"
}, {
  id: 11,
  parentId: 8,
  icon: "",
  name: "我的客户",
  url: "/kehuguanli/Customer"
}, {
  id: 21,
  parentId: 8,
  icon: "",
  name: "所有客户",
  url: "/kehuguanli/AllCustomer"
}, {
  id: 9,
  parentId: 0,
  icon: "laugh",
  name: "操作日志",
  url: "/jianducaozuo"
}, {
  id: 104,
  parentId: 9,
  icon: "",
  name: "操作日志",
  url: "/jianducaozuo/AllCustomer"
}, {
  id: 101,
  parentId: 10,
  icon: "",
  name: "风控查询",
  url: "/BigData/RiskQuery"
}, {
  id: 102,
  parentId: 10,
  icon: "",
  name: "房屋估值",
  url: "/BigData/HousingValuation"
}, {
  id: 70,
  parentId: 0,
  icon: "laugh",
  name: "推广管理",
  url: "/Popularization"
}, // {
//   id: 73,
//   parentId: 70,
//   name: "员工推广",
//   url: "/Popularization/bank"
// },
{
  id: 744,
  parentId: 70,
  name: "机构推广",
  url: "/Popularization/mechanism"
}, {
  id: 733,
  parentId: 70,
  name: "我的推广",
  url: "/Popularization/Statistics"
}, {
  id: 766,
  parentId: 70,
  name: "推广设置",
  url: "/Popularization/settingMork"
}];