import "core-js/modules/es.array.map";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/es.string.split";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import EmployeeSelect from "../RoleSelect/employee-select";
import { isJson } from "./index";
export default {
  name: "StartDrawer",
  components: {
    EmployeeSelect: EmployeeSelect
  },
  props: {
    startOptions: {
      type: Object,
      default: function _default() {}
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      visible: false,
      choseEmployees: [],
      defaultKeys: [],
      copyStartoptions: {},
      options: null
    };
  },
  computed: {},
  watch: {
    show: function show(val) {
      if (val) {
        this.copyStartoptions = JSON.parse(JSON.stringify(this.startOptions));
        this.initOptions(this.startOptions);
      }
    }
  },
  created: function created() {
    this.initOptions(this.startOptions);
  },
  mounted: function mounted() {},
  methods: {
    initOptions: function initOptions(val) {
      this.options = Object.assign({}, val, {
        props: isJson(val.props) ? JSON.parse(val.props) : val.props
      });

      if (this.options.limitUser) {
        this.defaultKeys = this.options.limitUser.split(",");
      }
    },
    onEmployeeChange: function onEmployeeChange(data) {
      console.log("data", data);
      this.choseEmployees = data;
      this.defaultKeys = data;
      this.startOptions.limitUser = this.choseEmployees.map(function (item) {
        return item.id;
      }).toString();
      this.startOptions.props = this.choseEmployees.map(function (item) {
        return item.name;
      }).toString();
      this.$emit("change", this.startOptions);
    },
    onOk: function onOk() {
      // 最后确认
      this.startOptions.limitUser = this.choseEmployees.map(function (item) {
        return item.id;
      }).toString();
      this.startOptions.props = this.choseEmployees.map(function (item) {
        return item.name;
      }).toString();
      this.$emit("change", this.startOptions);
      this.$emit("update:show", false); // if(this.startOptions.limitUser && this.startOptions.limitUser.length > 0){
      //   this.$emit("change", this.startOptions)
      //   this.$emit("update:show", false)
      // }else{
      //   this.$message.error("必须选择发起人")
      // }

      console.log("this.startOptions", this.startOptions);
    },
    onClose: function onClose() {
      // 关闭抽屉
      this.$emit("update:show", false);
      this.$emit("change", this.copyStartoptions);
    },
    addUser: function addUser() {
      // 添发起人
      this.visible = true;
    }
  }
};