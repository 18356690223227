import "core-js/modules/es.array.index-of";
import "core-js/modules/es.function.name";
import { getToken } from "xqjr-module-auth";
import { getPageTitle } from "@/utils/tool";
import NProgress from "nprogress/nprogress";
import Vue from "vue";
import VueRouter from "vue-router";
import routeConfigs from "./routeConfigs";
Vue.use(VueRouter);
NProgress.configure({
  showSpinner: false
});

var createRouter = function createRouter() {
  return new VueRouter({
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: routeConfigs
  });
};

var router = createRouter();
var white_list = ["/auth/login", "/auth/forget", "/404"]; // 访问前用户状态判断

router.beforeEach(function (to, from, next) {
  if (from.name !== null) {
    NProgress.start();
  }

  if (process.env.VUE_APP_DYNAMIC_CONFIG === "false") {
    document.title = getPageTitle(to.meta.title);
  }

  if (white_list.indexOf(to.path) > -1) {
    next();
  } else {
    var token = getToken();

    if (!!token) {
      if (to.path === "/auth/login") {
        next({
          path: "/"
        });
      } else {
        next();
      }
    } else {
      next("/auth/login?redirect=".concat(to.path));
    }
  }
});
router.afterEach(function () {
  NProgress.done();
});
export var resetRouter = function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher;
};
export default router;