var state = {
  projectId: null,
  projectName: null
};
var mutations = {
  saveCompany: function saveCompany(state, payload) {
    state.projectId = JSON.parse(payload).projectId;
    state.projectName = JSON.parse(payload).projectName;
  },
  clearCompany: function clearCompany(state) {}
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations
};