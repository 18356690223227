import Vue from "vue";
import Vuex from "vuex";
import getters from "./getters";
import app from "./modules/app";
import navtab from "./modules/navtab";
import settings from "./modules/settings";
import project from "./modules/project";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);
var store = new Vuex.Store({
  modules: {
    app: app,
    navtab: navtab,
    settings: settings,
    project: project
  },
  getters: getters,
  plugins: [createPersistedState({
    storage: window.sessionStorage
  })]
});
export default store;