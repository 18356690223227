var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "body" },
    [
      _c(
        "div",
        { style: { marginBottom: "10px" } },
        [
          _c(
            "span",
            { staticStyle: { "font-size": "20px", "font-weight": "bold" } },
            [
              _vm._v(
                " 客户姓名 : " +
                  _vm._s(
                    _vm.record.customerVO ? _vm.record.customerVO.name : "---"
                  ) +
                  " "
              )
            ]
          ),
          this.$route.params.status != 3
            ? _c(
                "a-button",
                { staticStyle: { float: "right" }, on: { click: _vm.goBack } },
                [_vm._v(" 返回 ")]
              )
            : _c(
                "a-button",
                {
                  staticStyle: { float: "right" },
                  on: { click: _vm.goToBack }
                },
                [_vm._v("返回")]
              ),
          this.$route.params.status != 2 && this.$route.params.status != 3
            ? _c(
                "span",
                [
                  _c(
                    "a-button",
                    {
                      staticStyle: { float: "right" },
                      style: { marginRight: "15px" },
                      attrs: {
                        disabled: this.$route.params.status == 1 ? false : true
                      },
                      on: { click: _vm.disagreeRisk }
                    },
                    [_vm._v(" 拒绝 ")]
                  ),
                  _c(
                    "a-button",
                    {
                      staticStyle: { float: "right" },
                      style: { marginRight: "15px" },
                      attrs: {
                        type: "primary",
                        disabled: this.$route.params.status == 1 ? false : true
                      },
                      on: { click: _vm.agreeRisk }
                    },
                    [_vm._v(" 同意 ")]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "a-row",
        [
          this.$route.params.status != 3
            ? _c("a-col", { attrs: { span: 8 } }, [
                _c("span", [
                  _vm._v(
                    "申请产品 : " +
                      _vm._s(
                        _vm.order.productName == null
                          ? "暂无产品"
                          : _vm.order.productName
                      )
                  )
                ])
              ])
            : _vm._e(),
          _c("a-col", { attrs: { span: 8 } }, [
            _c("span", [_vm._v("客户来源 : 分销")])
          ]),
          this.$route.params.status != 3
            ? _c("a-col", { attrs: { span: 8 } }, [
                _c("span", [
                  _vm._v("申请金额 : "),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "font-size": "18px",
                        "font-weight": "bold"
                      }
                    },
                    [_vm._v(_vm._s(_vm.order.amount))]
                  )
                ])
              ])
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "tabs" },
        [
          _c(
            "xq-tabs",
            { attrs: { defaultActiveKey: _vm.key } },
            [
              this.$route.params.status != 3
                ? _c(
                    "a-tab-pane",
                    { key: "intention", attrs: { tab: "借款意向" } },
                    [
                      _c("Intention", {
                        attrs: { editable: _vm.editable, status: _vm.status },
                        on: { data: _vm.intentionData }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              this.$route.params.status != 3
                ? _c(
                    "a-tab-pane",
                    { key: "baseinfo", attrs: { tab: "基本资料" } },
                    [
                      _c("Baseinfo", {
                        attrs: {
                          status: _vm.status,
                          productId: _vm.order.productId
                        },
                        on: { baseInfoData: _vm.baseInfoDatas }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              this.$route.params.status != 3
                ? _c(
                    "a-tab-pane",
                    { key: "CustomerFile", attrs: { tab: "影像资料" } },
                    [_c("CustomerFile", { attrs: { editable: _vm.editable } })],
                    1
                  )
                : _vm._e(),
              this.$route.params.status != 3
                ? _c(
                    "a-tab-pane",
                    { key: "report", attrs: { tab: "审批意见" } },
                    [
                      _c("Report", {
                        attrs: {
                          customerInfo: _vm.record,
                          orderInfoIds: _vm.orderInfoIds,
                          customId: _vm.customId,
                          editable: _vm.editable,
                          status: _vm.status
                        },
                        on: { reportData: _vm.reportDatas }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              this.$route.params.status != 3
                ? _c(
                    "a-tab-pane",
                    { key: "riskQuery", attrs: { tab: "风控查询" } },
                    [
                      _c("RistQueryTable", {
                        attrs: {
                          orderInfoId: _vm.orderInfoId,
                          customId: _vm.customId
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              this.$route.params.status != 3
                ? _c(
                    "a-tab-pane",
                    { key: "housingValuation", attrs: { tab: "房屋估值" } },
                    [
                      _c("HousingValuation", {
                        attrs: {
                          needTitle: false,
                          customerInfo: _vm.record,
                          customId: _vm.customId,
                          orderInfoId: _vm.orderInfoId
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              this.$route.params.status != 3
                ? _c(
                    "a-tab-pane",
                    { key: "contractList", attrs: { tab: "合同报告下载" } },
                    [
                      _c("ContractList", {
                        attrs: {
                          customId: _vm.customId,
                          orderInfoIds: _vm.orderInfoIds
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              this.$route.params.status == 3
                ? _c(
                    "a-tab-pane",
                    { key: "order", attrs: { tab: "订单列表" } },
                    [
                      _c("Order", {
                        attrs: { customId: _vm.customId, status: _vm.status }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { title: "提示", visible: _vm.confirmAlert },
          on: {
            ok: _vm.confirmHandle,
            cancel: function($event) {
              _vm.confirmAlert = false
            }
          }
        },
        [_c("p", { staticClass: "f500" }, [_vm._v("确定同意该审批吗")])]
      ),
      _c(
        "a-modal",
        {
          attrs: { title: _vm.title },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c(
            "template",
            { slot: "footer" },
            [
              _c("a-button", { key: "back", on: { click: _vm.handleCancel } }, [
                _vm._v(" 返回 ")
              ]),
              _vm.title == "拒绝"
                ? _c(
                    "a-button",
                    {
                      key: "submit",
                      attrs: { type: "primary" },
                      on: { click: _vm.handleOk }
                    },
                    [_vm._v(" 确定 ")]
                  )
                : _c(
                    "a-button",
                    {
                      key: "submit",
                      attrs: { type: "primary" },
                      on: { click: _vm.handleReject }
                    },
                    [_vm._v(" 确定 ")]
                  )
            ],
            1
          ),
          _c(
            "a-form-model",
            { attrs: { model: _vm.form, layout: "horizontal" } },
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "拒绝原因 :" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: { showSearch: "", placeholder: "请选择" },
                      model: {
                        value: _vm.form.rejectReason,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "rejectReason", $$v)
                        },
                        expression: "form.rejectReason"
                      }
                    },
                    [
                      _c("a-select-option", { attrs: { value: 1 } }, [
                        _vm._v("偿债能力不足")
                      ]),
                      _c("a-select-option", { attrs: { value: 2 } }, [
                        _vm._v("不符合我行进件要求")
                      ]),
                      _c("a-select-option", { attrs: { value: 3 } }, [
                        _vm._v("不符合我行政策要求")
                      ]),
                      _c("a-select-option", { attrs: { value: 4 } }, [
                        _vm._v("综合评价风险高")
                      ]),
                      _c("a-select-option", { attrs: { value: 5 } }, [
                        _vm._v("其他")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "拒绝原因详情 :" } },
                [
                  _c("a-input", {
                    attrs: {
                      type: "textarea",
                      placeholder: "请至少列举三条客户拒绝原因风险点"
                    },
                    model: {
                      value: _vm.form.rejectReasonDetail,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "rejectReasonDetail", $$v)
                      },
                      expression: "form.rejectReasonDetail"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }