import "core-js/modules/es.array.concat";
import _objectSpread from "/data/jenkins/workspace/prod-e-retail/node_modules/@babel/runtime/helpers/esm/objectSpread2";

/* eslint-disable eol-last */
import request from "@/utils/request"; // 获取百融贷前嵌入式报告

export function getBrPreLoanReport(data) {
  return request({
    url: "/finance/api/v1/windControlReport/getBrPreLoanReport",
    method: "GET",
    params: data
  });
} // 新增客户信息

export function addCustomerInfos(data) {
  return request({
    url: "/finance/api/v1/customerInfos",
    method: "POST",
    data: data
  });
} // 修改客户信息

export function editCustomerInfos(data) {
  return request({
    url: "/finance/api/v1/customerInfos",
    method: "PUT",
    data: data
  });
} // 批量删除客户信息，彻底删除

export function deleteCustomerInfos(data) {
  return request({
    url: "/finance/api/v1/customerInfos",
    method: "DELETE",
    data: data
  });
} // 批量编辑、新增、删除客户信息

export function batchHandleCustomerInfos(data) {
  return request({
    url: "/finance/api/v1/customerInfos/batch",
    method: "POST",
    data: data
  });
} // 根据订单id查询客户信息详情

export function getCustomerByOrderId(orderId) {
  return request({
    url: "/finance/api/v1/customerInfos/getCustomerByOrderId/".concat(orderId),
    method: "GET"
  });
} // 根据订单id查询担保人信息详情

export function getGuaranteeCustomerByOrderId(orderId) {
  return request({
    url: "/finance/api/v1/customerInfos/getGuaranteeCustomerByOrderId/".concat(orderId),
    method: "GET"
  });
} // 查询客户信息

export function checkCustomerInfos() {
  return request({
    url: "/finance/api/v1/customerInfos/listAll",
    method: "GET"
  });
} // 根据客户信息id查询客户信息详情

export function getCustomerInfoById(customerInfoId) {
  return request({
    url: "/finance/api/v1/customerInfos/".concat(customerInfoId),
    method: "GET"
  });
} // 删除单个客户信息，彻底删除

export function deleteCustomerInfo(customerInfoId) {
  return request({
    url: "/finance/api/v1/customerInfos/".concat(customerInfoId),
    method: "DELETE"
  });
} // ******************订单信息*****************
// 分页查询订单信息

export function getOrderlistByPage(data) {
  return request({
    url: "/finance/api/v1/orderInfos",
    method: "GET",
    params: data
  });
} // 新增订单信息

export function addOrderInfos(data) {
  return request({
    url: "/finance/api/v1/orderInfos",
    method: "POST",
    data: data
  });
} // 修改订单信息

export function updateOrderInfos(data) {
  return request({
    url: "/finance/api/v1/orderInfos",
    method: "PUT",
    data: data
  });
} // 批量删除订单信息，彻底删除

export function batchDeleteOrders(data) {
  return request({
    url: "/finance/api/v1/orderInfos",
    method: "DELETE"
  });
} // 分发订单

export function distributeOrder(data) {
  return request({
    url: "/finance/api/v1/orderInfos/distributeOrder",
    method: "POST",
    data: data
  });
} // 查询订单信息

export function checkOrderlistAll(data) {
  return request({
    url: "/finance/api/v1/orderInfos/listAll",
    method: "GET",
    params: data
  });
} // 接收分发订单

export function receiveOrder(data) {
  return request({
    url: "/finance/api/v1/orderInfos/receiveOrder",
    method: "POST",
    data: data
  });
} // 修改审核状态

export function updateAuditStatus(data) {
  return request({
    url: "/finance/api/v1/orderInfos/updateAuditStatus/${orderInfoId}/${status}",
    method: "PUT",
    data: data
  });
} // 每期还款-提前还款保存接口

export function updateEarlyOrderRepayment(data) {
  return request({
    url: "/finance/api/v1/orderInfos/updateEarlyOrderRepayment",
    method: "PUT",
    data: data
  });
} // 修改订单状态

export function updateOrderStatus(orderInfoId, status) {
  return request({
    url: "/finance/api/v1/orderInfos/updateOrderStatus/".concat(orderInfoId, "/").concat(status),
    method: "PUT"
  });
} // 根据订单信息id查询订单信息详情

export function checkOrderInfoById(orderInfoId) {
  return request({
    url: "/finance/api/v1/orderInfos/".concat(orderInfoId),
    method: "GET"
  });
} // 删除单个订单信息，彻底删除

export function deleteOrder(orderInfoId) {
  return request({
    url: "/finance/api/v1/orderInfos/".concat(orderInfoId),
    method: "DELETE"
  });
} // 产品信息
// GET
// 分页查询产品信息

export function getProductlistReq(data) {
  return request({
    url: "/finance/api/v1/products",
    method: "GET",
    params: data
  });
} // 查询产品信息

export function getAllProduct(data) {
  return request({
    url: "/finance/api/v1/channels/productList/".concat(localStorage.getItem("lesseeId")),
    method: "GET",
    params: data
  });
} // 新增产品信息

export function addProductReq(data) {
  return request({
    url: "/finance/api/v1/products",
    method: "POST",
    data: data
  });
} // 修改产品信息

export function editProductReq(data) {
  return request({
    url: "/finance/api/v1/products",
    method: "PUT",
    data: data
  });
}
export function updateHotProductBatch(data) {
  return request({
    url: "/finance/api/v1/products/updateHotProductBatch",
    method: "PUT",
    data: data
  });
} // 批量删除产品信息，彻底删除

export function batchDeleteProduct(data) {
  return request({
    url: "/finance/api/v1/products",
    method: "DELETE",
    data: data
  });
} // GET
// /finance/api/v1/products/findProductByAddressInPage
// findProductByAddressInPage
// /finance/api/v1/products/{productId}
// 根据产品信息id查询产品信息详情

export function getProductInfoById(productId) {
  return request({
    url: "/finance/api/v1/products/".concat(productId),
    method: "GET"
  });
} // DELETE
// 删除单个产品信息，彻底删除

export function deleteProduct(productId) {
  return request({
    url: "/finance/api/v1/products/".concat(productId),
    method: "DELETE"
  });
} // 查询产品启用类型

export function getEnableProductType(data) {
  return request({
    url: "/finance/api/v1/siteConfigs/getEnableProductType",
    method: "GET",
    params: data
  });
} // 修改产品启用

export function updateEnableProductType(data) {
  return request({
    url: "/finance/api/v1/siteConfigs/updateEnableProductType",
    method: "PUT",
    data: data
  });
} // 查询产品模板和二维码模板

export function getTemplateList(data) {
  return request({
    url: "/finance/api/v1/templates/listAll",
    method: "GET",
    params: _objectSpread(_objectSpread({}, data), {}, {
      channelId: localStorage.getItem("lesseeId"),
      source: process.env.VUE_APP_SOURCE
    })
  });
} // 新增产品模板和二维码模板

export function addTemplate(data) {
  return request({
    url: "/finance/api/v1/templates",
    method: "POST",
    data: _objectSpread(_objectSpread({}, data), {}, {
      channelId: localStorage.getItem("lesseeId"),
      source: process.env.VUE_APP_SOURCE
    })
  });
} // 删除单个产品模板和二维码模板，彻底删除

export function delTemplate(data) {
  return request({
    url: "/finance/api/v1/templates/".concat(data),
    method: "DELETE"
  });
} // 滞纳金
// 查询公式模板

export function getFormulaslist(data) {
  return request({
    url: "/finance/api/v1/formulas/listAll",
    method: "GET",
    params: data
  });
} // 新增公式模板

export function addFormula(data) {
  return request({
    url: "/finance/api/v1/formulas",
    method: "POST",
    data: data
  });
} // 修改公式模板

export function updateFormula(data) {
  return request({
    url: "/finance/api/v1/formulas",
    method: "PUT",
    data: data
  });
} // 删除单个公式模板，彻底删除

export function delFormula(formulaId) {
  return request({
    url: "/finance/api/v1/formulas/".concat(formulaId),
    method: "DELETE"
  });
}